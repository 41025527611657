import React, { Component } from 'react';
import { graphql, compose } from 'react-apollo';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import { FaEdit } from 'react-icons/fa';
// core components
import GridItem from '../../../../components/Grid/GridItem';
import GridContainer from '../../../../components/Grid/GridContainer';
import Button from '../../../../components/CustomButtons/Button';
import Card from '../../../../components/Card/Card';
import CardHeader from '../../../../components/Card/CardHeader';
import CardBody from '../../../../components/Card/CardBody';
import CardFooter from '../../../../components/Card/CardFooter';
import CustomInput from '../../../../components/CustomInput/CustomInput';
import { getConstantsSystems } from './queries';
import { updateConstantsSystems } from './mutation';
import styles from './style';
import { Dialog } from '../../../../components';


class Detail extends Component {
  constructor(props) {
    super(props);
    const { constantsSystems } = this.props;
    this.state = {
      edit: false,
      coinShareApp: constantsSystems ? +constantsSystems.coinShareApp : 0,
      coinShareOffer: constantsSystems ? +constantsSystems.coinShareOffer : 0,
      coinSharePromo: constantsSystems ? +constantsSystems.coinSharePromo : 0,
      coinReviewOffer: constantsSystems ? +constantsSystems.coinReviewOffer : 0,
      coinReceiveShare: constantsSystems ? +constantsSystems.coinReceiveShare : 0,
      walletConsumer: constantsSystems ? +constantsSystems.walletConsumer : 0,
      walletUser: constantsSystems ? +constantsSystems.walletUser : 0,
      shareOfferCoinMerchant: constantsSystems ? +constantsSystems.shareOfferCoinMerchant : 0,
      shareAppCoinMerchant: constantsSystems ? +constantsSystems.shareAppCoinMerchant : 0,
      shareOfferCoinForConsumer: constantsSystems ? +constantsSystems.shareOfferCoinForConsumer : 0,      
      open: false,
      title: '',
      content: '',
    };
  }

  componentWillReceiveProps = (nextProps) => {
    const { constantsSystems } = this.props;
    if (nextProps.constantsSystems !== constantsSystems) {
      const { constantsSystems } = nextProps;
      this.setState({
        coinShareApp: constantsSystems ? +constantsSystems.coinShareApp : 0,
        coinShareOffer: constantsSystems ? +constantsSystems.coinShareOffer : 0,
        coinSharePromo: constantsSystems ? +constantsSystems.coinSharePromo : 0,
        coinReviewOffer: constantsSystems ? +constantsSystems.coinReviewOffer : 0,
        coinReceiveShare: constantsSystems ? +constantsSystems.coinReceiveShare : 0,
        walletConsumer: constantsSystems ? +constantsSystems.walletConsumer : 0,
        walletUser: constantsSystems ? +constantsSystems.walletUser : 0,
        shareOfferCoinMerchant: constantsSystems ? +constantsSystems.shareOfferCoinMerchant : 0,
        shareAppCoinMerchant: constantsSystems ? +constantsSystems.shareAppCoinMerchant : 0,
        shareOfferCoinForConsumer: constantsSystems ? +constantsSystems.shareOfferCoinForConsumer : 0,
      });
    }
  }

  onEdit = () => {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: +event.target.value });
  };

  onSaveProfile = async () => {
    const {
      coinShareApp, coinShareOffer, coinSharePromo, coinReviewOffer, coinReceiveShare, walletConsumer, walletUser, shareOfferCoinMerchant, shareAppCoinMerchant,shareOfferCoinForConsumer
    } = this.state;
    const { updateCategory } = this.props;
    console.log(updateCategory)
    updateCategory({
      variables: {
        id: 1,
        input: {
          coinShareApp, coinShareOffer, coinSharePromo, coinReviewOffer, coinReceiveShare, walletConsumer, walletUser,shareOfferCoinMerchant, shareAppCoinMerchant, shareOfferCoinForConsumer
        },
      },
      refetchQueries: [{ query: getConstantsSystems }],
    }).then((res) => {
      if (res) {
        this.setState({
          edit: false, open: true, content: 'Completed', title: 'Update Constants System',
        });
      }
    }).catch(({ graphQLErrors }) => {
      if (graphQLErrors) {
        this.setState({ open: true, content: 'Value invalid', title: 'Erros Update Constants System' });
      }
    });
  }

  render() {
    const { classes, loading } = this.props;
    if (loading) return null;
    const {
      edit, coinShareApp, coinShareOffer, coinSharePromo, coinReviewOffer, coinReceiveShare, walletConsumer, walletUser, shareOfferCoinMerchant, shareAppCoinMerchant, shareOfferCoinForConsumer, title, open, content,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <Dialog
            title={title}
            content={content}
            open={open}
            onOk={() => this.setState({ open: false })}
          />
          <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader className={classes.headCard}>
                <h4 className={classes.cardTitleWhite}>{ edit ? 'Edit Constants System' : 'Detail Constants System'}</h4>
                { edit ? <p className={classes.cardCategoryWhite}>Complete Constants System</p> : null }
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="New Consumer uses referral code"
                      id="coinShareApp"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +coinShareApp || null,
                        name: 'coinShareApp',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>

                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Merchant from shared Capp to new Sign up"
                      id="shareAppCoinMerchant"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +shareAppCoinMerchant || null,
                        name: 'shareAppCoinMerchant',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={10}>
                    <CustomInput
                      labelText="Merchant from shared Capp new Sign up"
                      id="shareOfferCoinForConsumer"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +shareOfferCoinForConsumer || null,
                        name: 'shareOfferCoinForConsumer',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>                

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Consumer Shares Offer"
                      id="coinShareOffer"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +coinShareOffer || null,
                        name: 'coinShareOffer',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Merchant from shared Capp new Sign up"
                      id="shareOfferCoinMerchant"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +shareOfferCoinMerchant || null,
                        name: 'shareOfferCoinMerchant',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Consumer Share Mapp Promo Code"
                      id="coinSharePromo"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +coinSharePromo || null,
                        name: 'coinSharePromo',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Share Review after Closing Deal"
                      id="sharePercentage"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +coinReviewOffer || null,
                        name: 'coinReviewOffer',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Consumer from shared Capp new Sign up"
                      id="coinReceiveShare"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +coinReceiveShare || null,
                        name: 'coinReceiveShare',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="New Consumer Sign up"
                      id="walletConsumer"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +walletConsumer || null,
                        name: 'walletConsumer',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Coins Setting For Signing Up Merchant"
                      id="walletUser"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        disabled: !edit,
                        value: +walletUser || null,
                        name: 'walletUser',
                        onChange: this.handleChange,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
              {
                edit
                  ? (
                    <CardFooter>
                      <Button className={classes.button} onClick={this.onSaveProfile}>Update Constants System</Button>
                    </CardFooter>
                  ) : null
              }
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card profile>
              <CardBody profile>
                <h6 className={classes.cardCategory}>Constants System</h6>
                <Button round className={classes.button} onClick={this.onEdit}>
                  <Typography component="p" className={classes.text}>{!edit ? 'Edit' : 'Cancel'}</Typography>
                  <FaEdit color="#ffffff" size={16} />
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

// Mark: default props
Detail.defaultProps = {
  classes: {},
  loading: false,
  constantsSystems: {},
  updateCategory: () => {},
};

// Mark: PropsType
Detail.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  constantsSystems: PropTypes.object,
  updateCategory: PropTypes.func,
};

// Mark: Map data grapql to props
const ConstantsSystems = graphql(getConstantsSystems, {
  props: ({ data }) => ({
    loading: data.loading,
    constantsSystems: data.getConstantsSystems,
  }),
});

const Update = graphql(updateConstantsSystems, { name: 'updateCategory' });

export default withStyles(styles)(compose(ConstantsSystems, Update)(Detail));
